import http from '@/utils/http';

/**
 * 字典数据
 */
export default {
  getList: {
    p: 'post,/mpc/item/statistic/json',
    r: (data) => http({ url: '/mpc/item/statistic/json', method: 'post', data })
  },
  add: {
    p: 'post,/system/dictdata/add',
    r: (data) => http({ url: '/system/dictdata/add', method: 'post', data })
  },
  update: {
    p: 'post,/system/dictdata/update',
    r: (data) => http({ url: '/system/dictdata/update', method: 'post', data })
  },
  del: {
    p: 'post,/system/dictdata/delete',
    r: (data) => http({ url: '/system/dictdata/delete', method: 'post', data })
  }
  // 获取列表
  // getList(data) {
  //   return http({
  //     // url: '/system/dictdata/getlist',
  //     url:'/mpc/item/statistic/json',
  //     method: 'post',
  //     data
  //   });
  // },
  // // 新增
  // add(data) {
  //   return http({
  //     url: 'system/dictdata/add',
  //     method: 'post',
  //     data
  //   });
  // },
  // update(data) {
  //   return http({
  //     url: 'system/dictdata/update',
  //     method: 'post',
  //     data
  //   });
  // },
  // // 删除
  // del(data) {
  //   return http({
  //     url: 'system/dictdata/delete',
  //     method: 'post',
  //     data
  //   });
  // }
};
