<template>
  <el-row class="home-page" :gutter="20" type="flex">

    <el-col :span="22">
      <div class="desktop-panel">
        <div class="header">
          <div class="header-title">实时概况 <p>更新时间：2019-11-06 10:53:55</p></div>
        </div>

        <div class="home-box">
          <div class="home-box-row">
            <div class="box-col" v-for="(amountItem,amountIndex) in amount" :key="amountIndex">
              <div class="box-col-item" v-for="(item,index) in amountItem.list" :key="index">
                <div class="overview-data-item">
                  <div class="data-item-title">
                    {{item.title}}
                  </div>
                  <div class="data-item-amount">
                    <span class="amount-today"><count-to :start-val="0" :end-val="item.toDay" :duration="2000"/></span>
                    <i class="amount-icon el-icon-bottom" v-if="item.state === 2"></i>
                    <i class="amount-icon el-icon-top" v-if="item.state === 1"></i>
                  </div>
                  <div class="data-item-amount-yesterday">
                    昨日：<span>{{item.yesterday}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>  
      </div>
      <div class="desktop-panel">
        <div class="header">
          <div class="header-title">数据面板</div>
        </div>
        <el-col :span="12">
          <LineCharts :charData="lineData" :id="'memberLine'"></LineCharts>
        </el-col>
        <el-col :span="12">
          <LineCharts :charData="lineData" :id="'memberLine'"></LineCharts>
        </el-col>
      </div>   
    </el-col>
    <!-- <el-col :span="6" hidden-sm-only> -->
      <!-- <RightPanel>
        <template v-slot:default>
          <BarCharts :charData="barData" :id="'topBar'"></BarCharts>
        </template>
      </RightPanel> -->
    <!-- </el-col> -->
  </el-row>
</template>

<script>
import Data from './data.js';

export default Data;



</script>
